<template>
  <section class="main-section shop-section">
    <div class="shop-card" v-if="shop">
      <div class="shop-imgs">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide class="slide" v-for="(s, i) in imgs" :key="i">
            <img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/space/shop/' + name + '/' + s" width="100%" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <div class="shop-content">
        <div class="shop-title">{{ shop.title }}</div>
        <div class="shop-addr">{{ shop.addr }}</div>
        <div class="shop-tel">{{ shop.tel }}</div>
      </div>
    </div>
  </section>
</template>
<script>
import 'swiper/css/swiper.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
    name: null,
    shop: null,
    imgs: null,
    ossPath: 'https://ctk-website.oss-cn-shanghai.aliyuncs.com/maier/m/space/shop/',
    swiperOption: {
      autoHeight: true,
      loop: false,
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction'
      }
    }
  }),
  created () {
    this.$store.commit('updateActiveNav', 2)
    this.name = this.$route.params.name
    if (this.name in this.$shops) {
      this.shop = this.$shops[this.name]
      this.imgs = []
      if (this.shop.imgs) {
        for (let i = 1; i <= this.shop.imgs; i++) {
          this.imgs.push(i + '.jpg')
        }
      } else {
        this.imgs.push('main.png')
      }
    }
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/shop.css';
</style>
